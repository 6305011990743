import {Button, Drawer, Layout, Menu} from 'antd';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';
import {useLocation, useNavigate} from 'react-router-dom';
import logo from './../../../assets/main-logo.png';
import mail from './../../../assets/icons/mail.png';
import phone from './../../../assets/icons/phone.png';
import locationIco from './../../../assets/icons/location.png';
import menuIco from './../../../assets/icons/menu-ico.png';

import './Header.less';
import {Link} from 'react-router-dom';
import {IRouteProps} from '../../../utils/types';
import {useSelector} from 'react-redux';
import {X} from '@phosphor-icons/react';

const {Header, Content} = Layout;

interface IMenuProps {
	label: string;
	key: string;
	icon: React.ReactNode;
}

const AppHeader = () => {
	const {t} = useTranslation();
	// @ts-ignore
	const pages = useSelector(({routes}) => routes.data);
	const location = useLocation();
	const choosenPage = location.pathname;
	const isMobile = useMediaQuery({maxWidth: 1200});
	const [menu, setMenu] = useState(false);

	const navigate = useNavigate();
	const menuItems = useMemo(() => {
		// @ts-ignore
		return pages
			.filter((el: IRouteProps) => el.navBar)
			.map((el: IRouteProps) => ({
				label: el.label,
				key: el.path,
				disabled: el.disabled,
				path: el.path,
				children:
					el.subMenu &&
					el.subMenu.map((subel: any) => ({
						...subel,
						onClick: () => {
							choosenPage !== subel.path && navigate(subel.path);
						},
						key: subel.path,
						path: subel.path
					})),
				onClick: () => {
					choosenPage !== el.path && !el.subMenu && el.path && navigate(el.path);
				}
			}));
	}, [pages, navigate, choosenPage]);

	// window.onbeforeunload = function () {
	// 	window.scrollTo(0, 0);
	// };
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);
	useEffect(() => {
		setMenu(false);
		window.scrollTo({top: 0, behavior: 'smooth'});
	}, [location.pathname]);
	const showDrawer = () => {
		setMenu(true);
	};
	return (
		<>
			<Header className="header">
				<div className="top-info">
					<div className="top-info_container">
						<div className="top-info_left-column">
							<img
								src={locationIco}
								alt=""
								className="logo_link__logo"
								onClick={() => {
									window.scrollTo({top: 0, behavior: 'smooth'});
								}}
							/>
							<p>7 Koryun str., off. 3, Yerevan, Armenia.</p>
						</div>
						<div className="top-info_right-column">
							<div className="right-column-items">
								<img src={phone} alt="ico" />
								<a href="tel:+37460370044">+374 60 370044</a>
							</div>
							<div className="right-column-items">
								<img src={mail} alt="ico" />
								<a href="mailto:info@itg.am">info@itg.am</a>
							</div>
						</div>
					</div>
				</div>
				<div className="botton-nav">
					<Content className="content_header">
						<Link to={'/'} className="logo_link">
							<img
								src={logo}
								alt=""
								className="logo_link__logo"
								onClick={() => {
									window.scrollTo({top: 0, behavior: 'smooth'});
								}}
							/>
						</Link>

						{!isMobile && (
							<div className="right-actions">
								<Menu
									onClick={() => {
										window.scrollTo({top: 0, behavior: 'smooth'});
									}}
									className="menu"
									items={menuItems}
									mode="horizontal"
									disabledOverflow
									defaultSelectedKeys={[choosenPage]}
									selectedKeys={[choosenPage]}
								/>
							</div>
						)}

						{isMobile && (
							<Drawer
								className="drawer"
								placement="right"
								onClose={() => setMenu(false)}
								open={menu}
								width={250}
								key="right">
								<div className="drawer-container">
									<p onClick={() => setMenu(false)} style={{marginBottom: 20}}>
										<X size={32} />
									</p>
									<Menu
										onClick={() => {
											window.scrollTo({top: 0, behavior: 'smooth'});
										}}
										style={{borderInlineEnd: 0}}
										className="menu"
										items={menuItems}
										mode="vertical"
										disabledOverflow
										defaultSelectedKeys={[choosenPage]}
										selectedKeys={[choosenPage]}
									/>
								</div>
							</Drawer>
						)}
						<div style={{display: 'flex', alignItems: 'center', gap: 12}}>
							<Link to={'/contact-us'}>
								<Button className="header-demo-btn">Request Demo</Button>
							</Link>

							{isMobile && (
								<div style={{display: 'flex', alignItems: 'center'}}>
									<img onClick={showDrawer} src={menuIco} alt="menu0ico" />
								</div>
							)}
						</div>
					</Content>
				</div>
			</Header>
		</>
	);
};

export default AppHeader;
